import data from "../data.json";
import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
function Service() {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.5 });
  const servVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };
  return (
    <div id="service" className="container service">
      <div className="accordion" id="accordionExample">
        <h1>Our Features & Services provided</h1>
        <p className="mb-5">
          Web Genius is a team dedicated to helping businesses thrive online. We
          provide a comprehensive suite of services designed to elevate your
          website's functionality and user experience.
        </p>
        {data.services.map((serv, index) => (
          <div className="my-1 service-box" key={index}>
            <div
              id={`serv${index}`}
              ref={ref}
              className="d-flex flex-row justify-content-between align-items-center service-button px-5 py-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${index}`}
              aria-expanded="false"
              aria-controls={`collapse${index}`}
            >
              <span>
                <motion.i
                  initial="hidden"
                  animate={isInView ? "visible" : "hidden"}
                  variants={servVariants}
                  transition={{ type: "spring" }}
                  className={serv.icon + " pe-3"}
                ></motion.i>
                {serv.title}
              </span>
              <i className="fa-solid fa-chevron-down"></i>
            </div>
            <div
              id={`collapse${index}`}
              className="service-desciption collapse px-3 py-2"
              aria-labelledby={`serv${index}`}
              data-bs-parent="#accordionExample"
            >
              {serv.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Service;
