import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";

function Formulaire() {
  const [isEmailSending, setIsEmailSending] = useState(false);
  const form = useRef();
  const formAnimations = {
    hidden: {
      opacity: 0,
      x: 50,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
    transition: {
      delay: 0.3,
    },
  };
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const templateId = process.env.REACT_APP_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;

  const sendEmail = (e) => {
    e.preventDefault();
    if (name.trim() === "") return toast.error("Name is required");
    if (email.trim() === "") return toast.error("Email is required");
    if (subject.trim() === "") return toast.error("Subject is required");
    if (message.trim() === "") return toast.error("Message is required");
    setIsEmailSending(true);
    emailjs
      .sendForm(serviceId, templateId, form.current, {
        publicKey: publicKey,
      })
      .then(
        () => {
          setIsEmailSending(false);
          toast.success("Message is sent successfully.");
        },
        (error) => {
          console.log(error)
          setIsEmailSending(false);
          toast.error("Failed to send message.");
        }
      );
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={formAnimations}
      className="formulaire p-5 h-100 mx-lg-0"
    >
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeButton={false}
        toastClassName="toast-style m-3 m-lg-0 "
      />
      <h1>Get in touch</h1>
      <form
        ref={form}
        onSubmit={sendEmail}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <input
          className="my-3 ps-1 w-100"
          type="text"
          name="user_name"
          placeholder="Name"
          id=""
          onChange={(e) => setName(e.target.value)}
        />
        <input
          className="my-3 ps-1 w-100"
          type="email"
          name="user_email"
          placeholder="Email"
          id=""
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="my-3 ps-1 w-100"
          type="text"
          name="subject"
          placeholder="Subject"
          id=""
          onChange={(e) => setSubject(e.target.value)}
        />
        <textarea
          className="my-3 ps-1 w-100"
          rows={5}
          type="text"
          name="message"
          placeholder="Message"
          id=""
          onChange={(e) => setMessage(e.target.value)}
        />
        <motion.button whileTap={{ scale: 0.85 }} className="px-3 py-2">
          Send Email
          {isEmailSending && 
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          }
        </motion.button>
      </form>
    </motion.div>
  );
}

export default Formulaire;
