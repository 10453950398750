import React from "react";
import data from "../data.json";
import TeamMembres from "./TeamMembres";
function Team() {
  return (
    <div id="team" className="container team">
      <h2>meet our team</h2>
      <p>
      Our multidisciplinary team comprises seasoned experts with diverse backgrounds and extensive experience in various 
      domains. Their passion for innovation, attention to detail, and commitment to excellence ensure that every project 
      we undertake is executed with precision and delivered on time, within budget, and to the highest standards of quality.
      </p>
      <div className="row py-5 py-lg-0 ">
      {data.Membres.map((membre, index) => (
           <TeamMembres key={index} membre={membre} />
          ))}
          </div>
    </div>
  );
}

export default Team;