import React from "react";
import { motion } from "framer-motion";
import Formulaire from "./Formulaire";
import { HashLink } from "react-router-hash-link";
function Home() {
  const homeAnimations = {
    hidden: {
      opacity: 0,
      x: -50,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
    transition: {
      delay: 0.5,
    },
  };
  return (
    <div id="home" className="container home  d-flex  align-items-center">
      <div className="row px-2 px-lg-0 ">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={homeAnimations}
          className="col-md-7 my-3 py-5 pe-lg-5 text-center text-lg-start d-flex flex-column align-items-center  align-items-lg-start justify-content-center"
        >
          <h1>
            {" "}
            <span>Webgenify</span> <br /> AI-Powered Development <br /> for a Brighter Future
          </h1>
          <p className="mx-2 mx-lg-0">
            <strong>At webgenify</strong> we're more than just developers; we're innovators.
            Our passion lies in harnessing the power of machine learning and artificial intelligence to create cutting-edge
            solutions that propel your business forward. From concept to deployment, we blend creativity with
            technology to deliver bespoke solutions tailored to your unique needs.
          </p>
          <HashLink to="/#about">
            <motion.button whileTap={{ scale: 0.85 }} className="px-3 py-2">
              Get Started <i className="fa-solid fa-hourglass-start m-2"></i>
            </motion.button>
          </HashLink>
        </motion.div>
        <div className="col-md-5  my-3 ">
          <Formulaire />
        </div>
      </div>
    </div>
  );
}

export default Home;
