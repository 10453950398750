import React, { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import { Link } from "react-router-dom";
function TeamMembres({ membre }) {
  const [isHoverd, setIsHovred] = useState(true);
  useEffect(() => {
    setIsHovred(membre.PlatformLinks.portfolio);
  }, [membre]);
  const ref = useRef(null);
  const isInView = useInView(ref, {
    amount: window.innerWidth > 768 ? 1 : 0.2,
  });
  const aboutVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <div
      ref={ref}
      className="col-md-4 my-3 my-lg-0 d-flex justify-content-center"
    >
      <div className="team-box text-center">
        <img
          src={require(`../img/Team/${membre.img}`)}
          className="img-fluid mb-3"
        />
        <motion.div
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={aboutVariants}
          transition={{ type: "spring" }}
        >
          <Link
            className={isHoverd ? "name pointer hoverd" : "name"}
            to={membre.PlatformLinks.portfolio}
          >
            {" "}
            {membre.Name}
          </Link>
          <p>{membre.description}</p>
          <div className="d-flex flex-row justify-content-center ">
            {membre.PlatformLinks.github !== "" && (
              <Link to={membre.PlatformLinks.github}>
                <i className="mx-2 fa-brands fa-square-github"></i>
              </Link>
            )}
            {membre.PlatformLinks.linkedin !== "" && (
              <Link to={membre.PlatformLinks.linkedin}>
                {" "}
                <i className="mx-2 fa-brands fa-linkedin"></i>
              </Link>
            )}
            {membre.PlatformLinks.email !== "" && (
              <Link >
                <i className="mx-2 fa-solid fa-square-envelope"></i>
              </Link>
            )}
            {membre.PlatformLinks.instagram !== "" && (
              <Link to={membre.PlatformLinks.instagram}>
                {" "}
                <i className="mx-2 fa-brands fa-square-instagram"></i>
              </Link>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default TeamMembres;
