import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { HashLink} from "react-router-hash-link";
import logo from "../img/logo2.png";
function Navbar() {
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById("navbar");
      if (window.scrollY > 0) {
        navbar.classList.add("navbar-scrolled");
      } else {
        navbar.classList.remove("navbar-scrolled");
      }
    };
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarAnimations = {
    hidden: {
      opacity: 0,
      y: -50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
    transition: {
      delay: 0.2,
    },
  };

  return (
    <motion.nav
      initial="hidden"
      animate="visible"
      variants={navbarAnimations}
      id="navbar"
      className="navbar px-5  navbar-expand-lg fixed-top"
    >
      <div className="container-fluid">
        <img src={logo} alt="" className="img-fluid logo" />
        <i
          id="menuIcon"
          className="fa-solid fa-bars d-lg-none my-3 my-lg-0"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        ></i>
        <div
          className="offcanvas offcanvas-start text-center"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-body  my-2 my-lg-0 d-flex justify-content-center justify-content-lg-end align-items-center">
            <ul className="navbar-nav justify-content-center">
              <li className="nav-item mx-0 mx-lg-3 my-2">
                <i
                  type="button"
                  className="fa-solid fa-xmark d-block d-lg-none"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></i>
              </li>
              <li className="nav-item mx-0 mx-lg-3 my-2">
                <HashLink smooth to="/#home" className="nav-link nav-link-ltr ">
                  Home
                </HashLink>
              </li>
              <li className="nav-item mx-0 mx-lg-3 my-2">
                <HashLink smooth to="/#about" className="nav-link nav-link-ltr">
                  About
                </HashLink>
              </li>
              <li className="nav-item mx-0 mx-lg-3 my-2">
                <HashLink smooth to="/#service" className="nav-link nav-link-ltr">
                  Services
                </HashLink>
              </li>

              <li className="nav-item mx-0 mx-lg-3 my-2">
                <HashLink smooth to="/#project" className="nav-link nav-link-ltr">
                  Projects
                </HashLink>
              </li>
              <li className="nav-item  mx-0 ms-lg-3 my-2">
                <HashLink smooth to="/#contact" className="nav-link nav-link-ltr">
                  Contacts
                </HashLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </motion.nav>
  );
}

export default Navbar;
