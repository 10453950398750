import React from "react";
import data from "../data.json";
function Statistics() {
  return (
    <>
     <div className="statistic-box d-flex flex-column justify-content-center align-items-center my-2 py-1">
        <h1 className="m-0">{data.ExperinceYears}+</h1>
        <p className="m-0"> Years Experience</p>
      </div>
      <div className="statistic-box d-flex flex-column justify-content-center align-items-center my-2 p-2">
        <h1 className="m-0">{data.projects.length}+</h1>
        <p className="m-0">Projects done</p>
      </div>
      <div className="statistic-box d-flex flex-column justify-content-center align-items-center my-2 p-2">
        <h1 className="m-0">{data.Client}+</h1>
        <p className="m-0">client Satisfied</p>
      </div>
      </>
  );
}

export default Statistics;
