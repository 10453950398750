import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { motion, useInView } from "framer-motion";
import logo from "../img/logo1.png";
import { HashLink } from "react-router-hash-link";
import data from "../data.json";
function Footer() {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.5 });
  const footerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <footer id="contact">
      <div
        ref={ref}
        className="container py-4 d-flex flex-column justify-content-center align-items-center"
      >
        <img src={logo} />
        <motion.div
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={footerVariants}
          transition={{ type: "spring" }}
          className="mt-3 d-flex flex-column justify-content-center align-items-center"
        >
          <ul className="list-unstyled text-center  sections m-0 mt-1 d-flex flex-row">
            <li>
              <HashLink className="Link mx-2" to="/#home">
                Home
              </HashLink>
            </li>
            <li>
              <HashLink className="Link mx-2" to="/#about">
                About
              </HashLink>
            </li>
            <li>
              <HashLink className="Link mx-2" to="/#team">
                Team
              </HashLink>
            </li>
            <li>
              <HashLink className="Link mx-2" to="/#service">
                Services
              </HashLink>
            </li>
            <li>
              <HashLink className="Link mx-2" to="/#project">
                Projects
              </HashLink>
            </li>
          </ul>
          <ul className=" list-unstyled text-center  contact m-0 mt-1 d-flex flex-column flex-lg-row">
            <li>
              <Link className="Link mx-2" to="">
                <i className="me-1 fa-solid fa-phone"></i>
                {data.phones}
              </Link>
            </li>
            <li>
              <Link className="Link mx-2" to={`mailto:${data.email}`}>
                <i className="me-1 fa-solid fa-envelope"></i>
                {data.email}
              </Link>
            </li>
            <li>
              <Link className="Link mx-2" to={data.Website}>
                <i className="me-1 fa-solid fa-globe"></i>
                webgenify
              </Link>
            </li>
          </ul>
          <p className="m-0 mt-4">
          webgenify 2024 &copy; Copyrights. All rights reserved.
          </p>
        </motion.div>
      </div>
    </footer>
  );
}

export default Footer;
