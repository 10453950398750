import React from "react";
import data from "../data.json";
import ProjectBox from "./ProjectBox";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
function Project() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <div id="project" className="container project text-center mb-3 mb-lg-0">
      <h2>Our Projects</h2>
      <p className="mb-5">
        Explore our project in more detail! Click "Discover" below to visit our
        website and learn everything you need to know.
      </p>
      <Carousel
        transitionDuration={0.5}
        autoPlay={true}
        rewind={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        arrows={false}
        dotListClass="custom-dot-list-style"
        responsive={responsive}
      >
        {data.projects.map((project, index) => (
          <ProjectBox key={index} project={project} />
        ))}
      </Carousel>
    </div>
  );
}

export default Project;
