import "./Styles.css";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Team from "./components/Team";
import Project from "./components/Project";
import Footer from "./components/Footer";
import Service from "./components/Service";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Home />
      <About/>
      <Team />
      <Service />
      <Project />
      <Footer/>
    </BrowserRouter>
  );
}

export default App;

