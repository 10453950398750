import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import Statistics from "./Statistics";
import { HashLink } from "react-router-hash-link";
function About() {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    amount: window.innerWidth > 768 ? 0.5 : 0.2,
  });
  const aboutVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
    transform: {
      delay: 0.5,
    },
  };
  const statisVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
    transform: {
      delay: 0.5,
    },
  };
  return (
    <div
      id="about"
      ref={ref}
      className="container about d-flex flex-column  justify-content-center align-items-center"
    >
      <h2 className="mb-5">Learn more about us</h2>
      <div className="row px-2 px-lg-0 ">
        <motion.div
          animate={isInView ? "visible" : "hidden"}
          variants={statisVariants}
          transition={{ type: "just" }}
          className="col-md-5 my-3 my-lg-0 d-flex flex-column justify-content-start"
        >
          <Statistics />
        </motion.div>
        <motion.div
          animate={isInView ? "visible" : "hidden"}
          variants={aboutVariants}
          transition={{ type: "spring" }}
          className="col-md-7 my-3 my-lg-0 text-center text-lg-start  d-flex flex-column justify-content-between align-items-center align-items-lg-end"
        >
          <h1>Trusted IT Sowftware Agency For Any Business Needed</h1>
          <p>
          With 3+ years of experience, our trusted IT software agency has satisfied over 
          10+ clients by delivering high-quality software solutions tailored to their specific business needs. 
          Our dedicated team of skilled professionals collaborates closely with clients to understand their requirements, 
          leveraging cutting-edge technologies to develop innovative and efficient software applications that streamline 
          operations, enhance productivity, and drive growth. Whether you require custom software development, 
          web or mobile app development, or enterprise-level solutions, we have the expertise to deliver robust and 
          scalable solutions that exceed your expectations.
          </p>
          <HashLink to="/#team">
            {" "}
            <motion.button whileTap={{ scale: 0.85 }} className="px-3 py-2">
              Our Team <i className="fa-solid fa-users ms-2"></i>
            </motion.button>
          </HashLink>
        </motion.div>
      </div>
    </div>
  );
}

export default About;
