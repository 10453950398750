import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { motion, useInView } from "framer-motion";
function ProjectBox({ project }) {
  const img = require(`../img/Project/${project.img}`);
  const cardStyle = {
    backgroundImage: `linear-gradient(to bottom, transparent 20%, var(--green) 90%), url('${img}')`,
  };
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.5 });
  const projectVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <div className="border-0 mx-2 mx-lg-3  project-box my-3 my-lg-0 d-flex justify-content-center">
      <div
        ref={ref}
        className="card p-2 d-flex flex-column align-items-center justify-content-end"
        style={cardStyle}
      >
        <motion.span
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={projectVariants}
          transition={{ type: "spring" }}
        >
          <h5 className="card-title">{project.title}</h5>
          {project.links.website && (
            <Link className="link" to={project.links.website}>
              {" "}
              <motion.div
                whileTap={{ scale: 0.85 }}
                className="px-5 py-2 card-button"
              >
                Discover
                <i className="ms-2 fa-solid fa-arrow-up-right-from-square pointer"></i>
              </motion.div>
            </Link>
          )}
        </motion.span>
      </div>
    </div>
  );
}

export default ProjectBox;
